<template>
  <div class="tt-icon group/clock tt-next-day-icon">
    <img src="@/assets/icons-new/clock-square.svg" alt="next-day" />
    <div class="tt-tooltip !hidden group-hover/clock:!block !font-inter">
      {{ $t('main_vpopup_next_day_tooltip') }}
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="sass" scoped>
@import "@/assets/styles/colors"
@import "@/assets/styles/vars"
@import "@/assets/styles/shared"

.tt-next-day-icon
  position: absolute
  top: -10px
  left: -10px
  cursor: pointer
</style>
